import {ApolloClient, InMemoryCache, ApolloClientOptions} from '@apollo/client';
import apolloConfigFn, {ApolloConfig, Context} from './apolloConfigFn';

export let apolloClient: ApolloClient<any> | null = null;

const createDefaultCache = () => new InMemoryCache();

function create({createCache, ...apolloConfig}: ApolloConfig, initialState: any) {
	const c = createCache || createDefaultCache;

	const config: ApolloClientOptions<any> = {
		connectToDevTools: process.browser,
		ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
		cache: c().restore(initialState || {}),
		...apolloConfig,
		defaultOptions: {
			mutate: {
				errorPolicy: 'all',
			},
			query: {
				errorPolicy: 'all',
			},
		},
	};

	return new ApolloClient(config);
}

export default function initApollo(initialState: any, ctx?: Context | null) {
	const apolloConfig = apolloConfigFn(ctx);

	if (!process.browser) {
		return create(apolloConfig, initialState);
	}

	// Reuse client on the client-side
	if (!apolloClient) {
		apolloClient = create(apolloConfig, initialState);
	}

	return apolloClient;
}
