import React from 'react';
import {renderToString} from 'react-dom/server';
import Head from 'next/head';
import {getDataFromTree, getMarkupFromTree} from '@apollo/client/react/ssr';
import {ApolloClient} from '@apollo/client';
import {withRouter} from 'next/router';
import initApollo from '../apollo/initApollo';
const withApolloClient = (App: any) => {
	class Apollo extends React.Component {
		static displayName = `withApolloClient(${App.displayName || App.name})`;

		static async getInitialProps(appContext: any) {
			const {Component, router, ctx} = appContext;

			let apolloClient;
			if (!process.browser) {
				apolloClient = initApollo(null, process.browser ? null : ctx);
			}

			let appProps = {};
			if (App.getInitialProps) {
				appProps = await App.getInitialProps({...appContext, apolloClient});
			}

			const apolloState: any = {};

			// Run all GraphQL queries in the component tree
			// and extract the resulting data
			if (!process.browser) {
				try {
					const app = (
						<App
							{...appProps}
							Component={Component}
							router={router}
							apolloState={apolloState}
							apolloClient={apolloClient}
						/>
					);
					// Run all GraphQL queries
					await Promise.all([
						getDataFromTree(app),
						getMarkupFromTree({
							renderFunction: renderToString,
							tree: app,
						}),
					]);
				} catch (error) {
					// Prevent Apollo Client GraphQL errors from crashing SSR.
					// Handle them in components via the data.error prop:
					// http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
					// @ts-ignore
					const currentError = error?.graphQLErrors && error?.graphQLErrors[0];
					if (currentError && currentError.code) {
						appContext.ctx.res
							.status(currentError.code)
							.send(renderToString(<h1>Ошибка {currentError.code}</h1>));
					}
					console.log(error);
				}

				// getDataFromTree does not call componentWillUnmount
				// head side effect therefore need to be cleared manually
				//Head.rewind();

				// Extract query data from the Apollo store
				apolloState.data = apolloClient && apolloClient.cache.extract();
			}

			return {
				...appProps,
				apolloState,
			};
		}

		apolloClient: ApolloClient<any>;

		constructor(props: any) {
			super(props);
			// `getDataFromTree` renders the component first, the client is passed off as a property.
			// After that rendering is done using Next's normal rendering pipeline
			this.apolloClient = props.apolloClient || initApollo(props.apolloState.data);
		}

		render() {
			if ('ShowData' in (this.props as any).router.query) {
				return <pre>{JSON.stringify(this.apolloClient.cache.extract(), null, 2)}</pre>;
			}
			return <App {...this.props} apolloClient={this.apolloClient} />;
		}
	}
	return withRouter(Apollo as any);
};

export default withApolloClient;
