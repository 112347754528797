import {InMemoryCache, defaultDataIdFromObject, ApolloClientOptions, createHttpLink} from '@apollo/client';
import {Cookies} from 'react-cookie';
import ApolloLinkTimeout from 'apollo-link-timeout';
import {Request, Response} from 'express';
import skipNulls from '../components/utils/skipNulls';
import fetchWithProgress from '../components/utils/fetchWithProgress';

const GRAPHQL_ENDPOINT = process.env.API_URL;
export type ApolloConfig = Omit<ApolloClientOptions<any>, 'cache'> & {createCache: any};

export type Context = {
	req: Request & {
		span?: any;
		universalCookies?: Cookies;
	};
	res: Response;
};

const apolloConfigFn = (ctx?: null | Context): ApolloConfig => {
	const headers: Record<string, any> = {};
	const req = ctx && ctx.req;

	if (!process.browser && req && req.header) {
		/* проброс IP клиента */
		headers['x-forwarded-for'] = req.ip;
		/* куки */
		headers.cookie = req.header('cookie');
		/* заголовок юзерагента */
		headers['user-agent'] = req.header('user-agent');
		/* заголовок реферера */
		headers.referer = req.header('referer');
	}

	const fetcher = process.browser ? fetchWithProgress : require('node-fetch');

	let link: any = createHttpLink({
		fetch: fetcher,
		uri: GRAPHQL_ENDPOINT,
		//credentials: 'same-origin',
		headers: skipNulls(headers),
		fetchOptions: {
			credentials: 'same-origin',
		},
	});

	if (!process.browser) {
		const timeoutLink = new ApolloLinkTimeout(5000); // ms
		link = timeoutLink.concat(link);
	}

	return {
		link,
		createCache() {
			return new InMemoryCache({
				dataIdFromObject: (object: any) => {
					if (object.id) {
						return object.__typename + ':' + object.id;
					}
					return defaultDataIdFromObject(object); // fall back to default handling
				},
			});
		},
	};
};

export default apolloConfigFn;
