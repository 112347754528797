function skipNulls<T>(obj: T): Partial<T> {
	const res: any = {};
	for (const key in obj) {
		if (obj[key] != null) {
			res[key] = obj[key];
		}
	}
	return res;
}

export default skipNulls;
