import React, {useContext} from 'react';
import withRouterPatched, {RouterPatched} from '../lib/withRouterPatched';

export const RouterContext = React.createContext<RouterPatched>(null as any);

// Temporary wrapper to use React 16.3 Context
export const AppInjectRouterContext = withRouterPatched(({router, children}: any) => {
	return <RouterContext.Provider value={router}>{children}</RouterContext.Provider>;
});

export function useRouter() {
	return useContext(RouterContext);
}
