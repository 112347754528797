import React from 'react';
import {Placement, ToastProps} from 'react-toast-notifications';
import css from './CustomToast.module.css';
import {CheckCircleIcon, CrossIcon, InfoCircleIcon, WarningTriangleIcon} from '../SvgIcon';
import cls from '../utils/cls';

const toastStates = {
	entering: {transform: 'translate3d(0px, 20px, 0px)', opacity: 0},
	entered: {transform: 'translate3d(0,0,0)', opacity: 1},
	exiting: {transform: 'translate3d(0px, -20px, 0px)', opacity: 0},
	exited: {transform: 'translate3d(0px, -20px, 0px)', opacity: 0},
};

const CustomToast: React.FC<ToastProps> = ({
	children,
	appearance,
	onDismiss,
	onMouseEnter,
	onMouseLeave,
	transitionState,
	transitionDuration,
}) => {
	return (
		<div
			className={cls(css.toast, css[appearance])}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={{
				transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`,
				...toastStates[transitionState],
			}}
		>
			<div className={css.icon}>
				{appearance === 'error' ? (
					<WarningTriangleIcon />
				) : appearance === 'success' ? (
					<CheckCircleIcon />
				) : (
					<InfoCircleIcon />
				)}
			</div>
			<div className={css.content}>{children}</div>
			<div className={css.close} onClick={() => onDismiss()}>
				<CrossIcon />
			</div>
		</div>
	);
};

export default CustomToast;
