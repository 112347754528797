import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const HomeIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M10.5495 2.53189C11.3874 1.82531 12.6126 1.82531 13.4505 2.5319L20.2005 8.224C20.7074 8.65152 21 9.2809 21 9.94406V19.7468C21 20.7133 20.2165 21.4968 19.25 21.4968H15.75C14.7835 21.4968 14 20.7133 14 19.7468V14.2468C14 14.1088 13.8881 13.9968 13.75 13.9968H10.25C10.1119 13.9968 9.99999 14.1088 9.99999 14.2468V19.7468C9.99999 20.7133 9.2165 21.4968 8.25 21.4968H4.75C3.7835 21.4968 3 20.7133 3 19.7468V9.94406C3 9.2809 3.29255 8.65152 3.79952 8.224L10.5495 2.53189ZM12.4835 3.6786C12.2042 3.44307 11.7958 3.44307 11.5165 3.6786L4.76651 9.37071C4.59752 9.51321 4.5 9.72301 4.5 9.94406V19.7468C4.5 19.8849 4.61193 19.9968 4.75 19.9968H8.25C8.38807 19.9968 8.49999 19.8849 8.49999 19.7468V14.2468C8.49999 13.2803 9.2835 12.4968 10.25 12.4968H13.75C14.7165 12.4968 15.5 13.2803 15.5 14.2468V19.7468C15.5 19.8849 15.6119 19.9968 15.75 19.9968H19.25C19.3881 19.9968 19.5 19.8849 19.5 19.7468V9.94406C19.5 9.72301 19.4025 9.51321 19.2335 9.37071L12.4835 3.6786Z" />
	</svg>
);

export const MoreIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M7.75 12C7.75 12.9665 6.9665 13.75 6 13.75C5.0335 13.75 4.25 12.9665 4.25 12C4.25 11.0335 5.0335 10.25 6 10.25C6.9665 10.25 7.75 11.0335 7.75 12ZM13.75 12C13.75 12.9665 12.9665 13.75 12 13.75C11.0335 13.75 10.25 12.9665 10.25 12C10.25 11.0335 11.0335 10.25 12 10.25C12.9665 10.25 13.75 11.0335 13.75 12ZM18 13.75C18.9665 13.75 19.75 12.9665 19.75 12C19.75 11.0335 18.9665 10.25 18 10.25C17.0335 10.25 16.25 11.0335 16.25 12C16.25 12.9665 17.0335 13.75 18 13.75Z" />
	</svg>
);

export const ChevronDownIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z" />
	</svg>
);

export const ChevronUpIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M4.21967 15.5303C4.51256 15.8232 4.98744 15.8232 5.28033 15.5303L12 8.81066L18.7197 15.5303C19.0126 15.8232 19.4874 15.8232 19.7803 15.5303C20.0732 15.2374 20.0732 14.7626 19.7803 14.4697L12.5303 7.21967C12.2374 6.92678 11.7626 6.92678 11.4697 7.21967L4.21967 14.4697C3.92678 14.7626 3.92678 15.2374 4.21967 15.5303Z" />
	</svg>
);
export const ArrowLeftIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M10.7327 19.7907C11.0326 20.0764 11.5074 20.0649 11.7931 19.765C12.0787 19.465 12.0672 18.9903 11.7673 18.7046L5.51587 12.75L20.25 12.75C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25L5.51577 11.25L11.7673 5.29529C12.0672 5.00961 12.0787 4.53487 11.7931 4.23495C11.5074 3.93502 11.0326 3.92348 10.7327 4.20916L3.31379 11.2759C3.14486 11.4368 3.04491 11.642 3.01393 11.8554C3.00479 11.9022 3 11.9505 3 12C3 12.0496 3.00481 12.098 3.01398 12.1449C3.04502 12.3581 3.14496 12.5632 3.31379 12.724L10.7327 19.7907Z" />
	</svg>
);

export const NavigationIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M2.75255 18.0002H21.2526C21.6668 18.0002 22.0026 18.336 22.0026 18.7502C22.0026 19.1299 21.7204 19.4437 21.3543 19.4933L21.2526 19.5002H2.75255C2.33834 19.5002 2.00255 19.1644 2.00255 18.7502C2.00255 18.3705 2.28471 18.0567 2.65078 18.007L2.75255 18.0002H21.2526H2.75255ZM2.75255 11.5032H21.2526C21.6668 11.5032 22.0026 11.839 22.0026 12.2532C22.0026 12.6329 21.7204 12.9467 21.3543 12.9963L21.2526 13.0032H2.75255C2.33834 13.0032 2.00255 12.6674 2.00255 12.2532C2.00255 11.8735 2.28471 11.5597 2.65078 11.51L2.75255 11.5032H21.2526H2.75255ZM2.75169 5.00342H21.2517C21.6659 5.00342 22.0017 5.3392 22.0017 5.75342C22.0017 6.13311 21.7195 6.44691 21.3535 6.49657L21.2517 6.50342H2.75169C2.33748 6.50342 2.00169 6.16763 2.00169 5.75342C2.00169 5.37372 2.28385 5.05993 2.64992 5.01026L2.75169 5.00342H21.2517H2.75169Z" />
	</svg>
);

export const CreateIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M11.7498 3C12.1295 3 12.4434 3.28201 12.4931 3.64808L12.5 3.74985L12.5012 11H19.7543C20.1685 11 20.5043 11.3358 20.5043 11.75C20.5043 12.1297 20.2221 12.4435 19.8561 12.4932L19.7543 12.5H12.5012L12.5032 19.7491C12.5033 20.1633 12.1676 20.4993 11.7534 20.4993C11.3737 20.4993 11.0598 20.2173 11.0101 19.8512L11.0032 19.7494L11.0012 12.5H3.7522C3.33798 12.5 3.0022 12.1642 3.0022 11.75C3.0022 11.3703 3.28435 11.0565 3.65043 11.0068L3.7522 11H11.0012L11 3.75015C10.9999 3.33594 11.3356 3 11.7498 3Z" />
	</svg>
);

export const EditIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M21.0303 2.96973C22.4278 4.36719 22.4278 6.63293 21.0303 8.03039L9.06201 19.9987C8.78513 20.2756 8.44079 20.4754 8.06303 20.5784L2.94734 21.9736C2.38747 22.1263 1.87374 21.6126 2.02643 21.0527L3.42162 15.937C3.52465 15.5593 3.72447 15.2149 4.00135 14.9381L15.9697 2.96973C17.3671 1.57227 19.6329 1.57227 21.0303 2.96973ZM15.0002 6.06063L5.06201 15.9987C4.96972 16.091 4.90311 16.2058 4.86877 16.3317L3.81892 20.1811L7.66836 19.1313C7.79428 19.097 7.90906 19.0303 8.00135 18.9381L17.9392 8.99963L15.0002 6.06063ZM17.0303 4.03039L16.0602 4.99963L18.9992 7.93963L19.9697 6.96973C20.7813 6.15805 20.7813 4.84206 19.9697 4.03039C19.158 3.21871 17.842 3.21871 17.0303 4.03039Z" />
	</svg>
);

export const SaveIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M3 5.75C3 4.23122 4.23122 3 5.75 3H15.7145C16.5764 3 17.4031 3.34241 18.0126 3.9519L20.0481 5.98744C20.6576 6.59693 21 7.42358 21 8.28553V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75ZM5.75 4.5C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H6V14.25C6 13.0074 7.00736 12 8.25 12H15.75C16.9926 12 18 13.0074 18 14.25V19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V8.28553C19.5 7.8214 19.3156 7.37629 18.9874 7.0481L16.9519 5.01256C16.6918 4.75246 16.3582 4.58269 16 4.52344V7.25C16 8.49264 14.9926 9.5 13.75 9.5H9.25C8.00736 9.5 7 8.49264 7 7.25V4.5H5.75ZM16.5 19.5V14.25C16.5 13.8358 16.1642 13.5 15.75 13.5H8.25C7.83579 13.5 7.5 13.8358 7.5 14.25V19.5H16.5ZM8.5 4.5V7.25C8.5 7.66421 8.83579 8 9.25 8H13.75C14.1642 8 14.5 7.66421 14.5 7.25V4.5H8.5Z" />
	</svg>
);

export const FilterIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M13.5 16C13.9142 16 14.25 16.3358 14.25 16.75C14.25 17.1642 13.9142 17.5 13.5 17.5H10.5C10.0858 17.5 9.75 17.1642 9.75 16.75C9.75 16.3358 10.0858 16 10.5 16H13.5ZM16.5 11C16.9142 11 17.25 11.3358 17.25 11.75C17.25 12.1642 16.9142 12.5 16.5 12.5H7.5C7.08579 12.5 6.75 12.1642 6.75 11.75C6.75 11.3358 7.08579 11 7.5 11H16.5ZM19.5 6C19.9142 6 20.25 6.33579 20.25 6.75C20.25 7.16421 19.9142 7.5 19.5 7.5H4.5C4.08579 7.5 3.75 7.16421 3.75 6.75C3.75 6.33579 4.08579 6 4.5 6H19.5Z" />
	</svg>
);

export const CheckIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M4.53033 12.9697C4.23744 12.6768 3.76256 12.6768 3.46967 12.9697C3.17678 13.2626 3.17678 13.7374 3.46967 14.0303L7.96967 18.5303C8.26256 18.8232 8.73744 18.8232 9.03033 18.5303L20.0303 7.53033C20.3232 7.23744 20.3232 6.76256 20.0303 6.46967C19.7374 6.17678 19.2626 6.17678 18.9697 6.46967L8.5 16.9393L4.53033 12.9697Z" />
	</svg>
);

export const CrossIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z" />
	</svg>
);

export const EyeOpenIcon = (props: Props) => (
	<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
		<path d="M3.25909 11.6021C3.94254 8.32689 6.79437 6 10 6C13.2057 6 16.0574 8.32688 16.7409 11.6021C16.7974 11.8725 17.0622 12.0459 17.3325 11.9895C17.6029 11.933 17.7763 11.6682 17.7199 11.3979C16.9425 7.67312 13.6934 5 10 5C6.3066 5 3.05742 7.67311 2.28017 11.3979C2.22377 11.6682 2.39718 11.933 2.6675 11.9895C2.93782 12.0459 3.20268 11.8725 3.25909 11.6021ZM10 8C8.067 8 6.5 9.567 6.5 11.5C6.5 13.433 8.067 15 10 15C11.933 15 13.5 13.433 13.5 11.5C13.5 9.567 11.933 8 10 8ZM7.5 11.5C7.5 10.1193 8.61929 9 10 9C11.3807 9 12.5 10.1193 12.5 11.5C12.5 12.8807 11.3807 14 10 14C8.61929 14 7.5 12.8807 7.5 11.5Z" />
	</svg>
);

export const EyeCloseIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M2.21967 2.21967C1.9534 2.48594 1.9292 2.9026 2.14705 3.19621L2.21967 3.28033L6.25424 7.3149C4.33225 8.66437 2.89577 10.6799 2.29888 13.0644C2.1983 13.4662 2.4425 13.8735 2.84431 13.9741C3.24613 14.0746 3.6534 13.8305 3.75399 13.4286C4.28346 11.3135 5.59112 9.53947 7.33416 8.39452L9.14379 10.2043C8.43628 10.9258 8 11.9143 8 13.0046C8 15.2138 9.79086 17.0046 12 17.0046C13.0904 17.0046 14.0788 16.5683 14.8004 15.8608L20.7197 21.7803C21.0126 22.0732 21.4874 22.0732 21.7803 21.7803C22.0466 21.5141 22.0708 21.0974 21.8529 20.8038L21.7803 20.7197L15.6668 14.6055L15.668 14.604L14.4679 13.4061L11.598 10.5368L11.6 10.536L8.71877 7.65782L8.72 7.656L7.58672 6.52549L3.28033 2.21967C2.98744 1.92678 2.51256 1.92678 2.21967 2.21967ZM10.2041 11.2655L13.7392 14.8006C13.2892 15.2364 12.6759 15.5046 12 15.5046C10.6193 15.5046 9.5 14.3853 9.5 13.0046C9.5 12.3287 9.76824 11.7154 10.2041 11.2655ZM12 5.5C10.9997 5.5 10.0291 5.64807 9.11109 5.925L10.3481 7.16119C10.8839 7.05532 11.4364 7 12 7C15.9231 7 19.3099 9.68026 20.2471 13.4332C20.3475 13.835 20.7546 14.0794 21.1565 13.9791C21.5584 13.8787 21.8028 13.4716 21.7024 13.0697C20.5994 8.65272 16.6155 5.5 12 5.5ZM12.1947 9.00928L15.996 12.81C15.8942 10.7531 14.2472 9.10764 12.1947 9.00928Z" />
	</svg>
);

export const User = (props: Props) => (
	<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
		<path d="M10 2C7.79086 2 6 3.79086 6 6C6 8.20914 7.79086 10 10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2ZM7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6ZM5.00873 11C3.90315 11 3 11.8869 3 13C3 14.6912 3.83281 15.9663 5.13499 16.7966C6.41697 17.614 8.14526 18 10 18C11.8547 18 13.583 17.614 14.865 16.7966C16.1672 15.9663 17 14.6912 17 13C17 11.8956 16.1045 11 15 11L5.00873 11ZM4 13C4 12.4467 4.44786 12 5.00873 12L15 12C15.5522 12 16 12.4478 16 13C16 14.3088 15.3777 15.2837 14.3274 15.9534C13.2568 16.636 11.7351 17 10 17C8.26489 17 6.74318 16.636 5.67262 15.9534C4.62226 15.2837 4 14.3088 4 13Z" />
	</svg>
);

export const HelpIcon = (props: Props) => (
	<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
		<path d="M10 3C7.79386 3 6 4.79386 6 7C6 7.27614 6.22386 7.5 6.5 7.5C6.77614 7.5 7 7.27614 7 7C7 5.34614 8.34614 4 10 4C11.6539 4 13 5.34614 13 7C13 8.2486 12.3078 8.86333 11.4246 9.62037L11.3935 9.64699C10.5328 10.3845 9.5 11.2695 9.5 13V13.5C9.5 13.7761 9.72386 14 10 14C10.2761 14 10.5 13.7761 10.5 13.5V13C10.5 11.7514 11.1922 11.1367 12.0754 10.3796L12.1065 10.353C12.9672 9.61545 14 8.73053 14 7C14 4.79386 12.2061 3 10 3ZM10 17C10.4142 17 10.75 16.6642 10.75 16.25C10.75 15.8358 10.4142 15.5 10 15.5C9.58579 15.5 9.25 15.8358 9.25 16.25C9.25 16.6642 9.58579 17 10 17Z" />
	</svg>
);

export const CheckCircleIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM10.75 13.4393L15.2197 8.96967C15.5126 8.67678 15.9874 8.67678 16.2803 8.96967C16.5466 9.23594 16.5708 9.6526 16.3529 9.94621L16.2803 10.0303L11.2803 15.0303C11.0141 15.2966 10.5974 15.3208 10.3038 15.1029L10.2197 15.0303L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697C7.98594 11.2034 8.4026 11.1792 8.69621 11.3971L8.78033 11.4697L10.75 13.4393L15.2197 8.96967L10.75 13.4393Z" />
	</svg>
);

export const InfoCircleIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M12 1.99902C17.5237 1.99902 22.0015 6.47687 22.0015 12.0006C22.0015 17.5243 17.5237 22.0021 12 22.0021C6.47626 22.0021 1.99841 17.5243 1.99841 12.0006C1.99841 6.47687 6.47626 1.99902 12 1.99902ZM12 3.49902C7.30469 3.49902 3.49841 7.3053 3.49841 12.0006C3.49841 16.6959 7.30469 20.5021 12 20.5021C16.6952 20.5021 20.5015 16.6959 20.5015 12.0006C20.5015 7.3053 16.6952 3.49902 12 3.49902ZM11.9963 10.4996C12.376 10.4994 12.69 10.7813 12.7399 11.1473L12.7468 11.2491L12.7504 16.7507C12.7507 17.1649 12.4151 17.5009 12.0009 17.5012C11.6212 17.5014 11.3072 17.2195 11.2573 16.8535L11.2504 16.7517L11.2468 11.2501C11.2465 10.8359 11.5821 10.4999 11.9963 10.4996ZM12.0004 7.00184C12.552 7.00184 12.9991 7.44896 12.9991 8.0005C12.9991 8.55205 12.552 8.99917 12.0004 8.99917C11.4489 8.99917 11.0017 8.55205 11.0017 8.0005C11.0017 7.44896 11.4489 7.00184 12.0004 7.00184Z" />
	</svg>
);

export const WarningTriangleIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M10.9094 2.78216C11.9492 2.20625 13.2472 2.54089 13.885 3.5224L13.9678 3.66023L21.7268 17.6685C21.9116 18.0021 22.0085 18.3773 22.0085 18.7587C22.0085 19.9495 21.0834 20.9243 19.9126 21.0035L19.7585 21.0087H4.24278C3.86147 21.0087 3.48642 20.9118 3.15283 20.7271C2.11299 20.1513 1.70795 18.8734 2.20151 17.812L2.27441 17.6687L10.0312 3.66046C10.2357 3.2911 10.5401 2.98674 10.9094 2.78216ZM20.4146 18.3953L12.6557 4.38702C12.455 4.02467 11.9985 3.89363 11.6362 4.09433C11.5439 4.14547 11.4637 4.21533 11.4006 4.299L11.3435 4.3871L3.58666 18.3954C3.386 18.7577 3.5171 19.2141 3.87947 19.4148C3.96286 19.461 4.05403 19.4907 4.14803 19.5027L4.24278 19.5087H19.7585C20.1727 19.5087 20.5085 19.1729 20.5085 18.7587C20.5085 18.6633 20.4904 18.5691 20.4553 18.4811L20.4146 18.3953L12.6557 4.38702L20.4146 18.3953ZM12.0005 16.0018C12.552 16.0018 12.9991 16.4489 12.9991 17.0004C12.9991 17.552 12.552 17.9991 12.0005 17.9991C11.4489 17.9991 11.0018 17.552 11.0018 17.0004C11.0018 16.4489 11.4489 16.0018 12.0005 16.0018ZM11.9963 8.49955C12.3759 8.49925 12.69 8.78115 12.7399 9.14718L12.7469 9.24895L12.7505 13.7505C12.7508 14.1648 12.4153 14.5008 12.0011 14.5011C11.6214 14.5014 11.3073 14.2195 11.2574 13.8535L11.2505 13.7517L11.2469 9.25015C11.2465 8.83594 11.582 8.49988 11.9963 8.49955Z" />
	</svg>
);

export const SortIcon = (props: Props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path d="M2.21965 9.21969L6.71441 4.72464C6.98047 4.45856 7.39676 4.43415 7.69038 4.65156L7.77451 4.72404L12.2794 9.2191C12.5726 9.51167 12.5731 9.98654 12.2805 10.2798C12.0145 10.5463 11.5979 10.571 11.3041 10.3534L11.2199 10.2809L7.997 7.06406L7.99766 18.7599C7.99766 19.1396 7.71551 19.4534 7.34943 19.503L7.24766 19.5099C6.86797 19.5099 6.55417 19.2277 6.50451 18.8617L6.49766 18.7599L6.497 7.06206L3.28035 10.2803C3.01409 10.5466 2.59743 10.5708 2.30381 10.353L2.21969 10.2804C1.95341 10.0141 1.92919 9.59743 2.14704 9.30381L2.21965 9.21969L6.71441 4.72464L2.21965 9.21969ZM16.6491 4.5019L16.7509 4.49506C17.1306 4.49506 17.4444 4.77721 17.494 5.14329L17.5009 5.24506L17.5 16.9411L20.7194 13.7199C20.9856 13.4535 21.4023 13.4291 21.696 13.6469L21.7801 13.7194C22.0465 13.9856 22.0709 14.4023 21.8531 14.696L21.7806 14.7801L17.2843 19.2801C17.0182 19.5465 16.6015 19.5709 16.3078 19.3531L16.2237 19.2806L11.7199 14.7806C11.4269 14.4878 11.4267 14.0129 11.7194 13.7199C11.9856 13.4535 12.4023 13.4291 12.696 13.6469L12.7801 13.7194L16 16.9371L16.0009 5.24506C16.0009 4.86536 16.283 4.55157 16.6491 4.5019L16.7509 4.49506L16.6491 4.5019Z" />
	</svg>
);
