import React, {ReactElement} from 'react';
import {SingletonRouter} from 'next/router';
// @ts-ignore
import {EventChangeOptions} from 'next-server/router';

export type SiteRoutes = {
	index: {};

	registration: {};

	classes: {};
	class: {id: string};
	classInfo: {id: string};
	classSpells: {id: string};
	subClass: {id: string; subClassId: string};
	subClasses: {id: string};
	classFeature: {id: string; featureId: string};
	subClassFeature: {id: string; subClassId: string; featureId: string};

	spells: {};
	spell: {id: string};

	races: {};
	race: {id: string};
	raceInfo: {id: string};
	subRace: {id: string; subRaceId: string};
	subRaces: {id: string};
	raceFeature: {id: string; featureId: string};
	subRaceFeature: {id: string; subRaceId: string; featureId: string};

	backgrounds: {};
	background: {id: string};
	backgroundFeature: {id: string; featureId: string};

	feats: {};
	feat: {id: string};

	monsters: {};
	monster: {id: string};
	monsterInfo: {id: string};

	items: {};
	item: {id: string};
};

//const nextRoutes = require('next-routes');
import NextRoutes from 'next-routes';

export type RouteParams<K extends keyof SiteRoutes> = SiteRoutes[K] & Partial<Record<string, string | number | null>>;
interface Router extends SingletonRouter {
	pushRoute<K extends keyof SiteRoutes>(
		route: K,
		params?: RouteParams<K>,
		options?: EventChangeOptions,
	): Promise<boolean>;
	replaceRoute<K extends keyof SiteRoutes>(
		route: K,
		params?: RouteParams<K>,
		options?: EventChangeOptions,
	): Promise<boolean>;
	prefetchRoute<K extends keyof SiteRoutes>(route: K, params?: RouteParams<K>): Promise<React.ComponentType<any>>;
}

const routes = new NextRoutes() as any as {
	add(name: keyof SiteRoutes, pattern?: string, page?: string): void;
	match(url: string): any;
	Link: any;
	Router: Router;
};

routes.add('index', '/', 'IndexPage');
routes.add('registration', '/registration', 'RegistrationPage');

routes.add('classes', '/classes', 'ClassesPage');
routes.add('class', '/classes/:id', 'ClassesPage');
routes.add('classInfo', '/classes/:id/about', 'ClassesPage');
routes.add('classSpells', '/classes/:id/spells', 'ClassesPage');
routes.add('subClasses', '/classes/:id/subclasses', 'ClassesPage');
routes.add('subClass', '/classes/:id/subclasses/:subClassId', 'SubClassPage');
routes.add('classFeature', '/classes/:id/:featureId', 'ClassesPage');
routes.add('subClassFeature', '/classes/:id/subclasses/:subClassId/:featureId', 'SubClassPage');

routes.add('spells', '/spells', 'SpellsPage');
routes.add('spell', '/spells/:id', 'SpellsPage');

routes.add('races', '/races', 'RacesPage');
routes.add('race', '/races/:id', 'RacesPage');
routes.add('raceInfo', '/races/:id/about', 'RacesPage');
routes.add('subRaces', '/races/:id/subraces', 'RacesPage');
routes.add('subRace', '/races/:id/subraces/:subRaceId', 'SubRacePage');
routes.add('raceFeature', '/races/:id/:featureId', 'RacesPage');
routes.add('subRaceFeature', '/races/:id/subraces/:subRaceId/:featureId', 'SubRacePage');

routes.add('backgrounds', '/backgrounds', 'BackgroundsPage');
routes.add('background', '/backgrounds/:id', 'BackgroundsPage');
routes.add('backgroundFeature', '/backgrounds/:id/:featureId', 'BackgroundsPage');

routes.add('feats', '/feats', 'FeatsPage');
routes.add('feat', '/feats/:id', 'FeatsPage');

routes.add('monsters', '/monsters', 'MonstersPage');
routes.add('monster', '/monsters/:id', 'MonstersPage');
routes.add('monsterInfo', '/monsters/:id/about', 'MonstersPage');

routes.add('items', '/items', 'ItemsPage');
routes.add('item', '/items/:id', 'ItemsPage');

export const Link = <K extends keyof SiteRoutes>(props: {
	children: React.ReactNode;
	route: K;
	params?: RouteParams<K>;
}) => {
	const {params} = props;
	const usefullParams: any = {};

	if (params) {
		for (const key in params) {
			const prop = params[key];
			if (prop !== undefined && prop !== null) usefullParams[key] = prop;
		}
	}

	return routes.Link({...props, params: usefullParams}) as ReactElement<any>;
};

export const Router = routes.Router;
export default routes;
