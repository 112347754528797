import {Url} from 'url';
import React from 'react';
import {object} from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import {SingletonRouter} from 'next/router';
import routes from '../server/routes';
import skipNulls from '../components/utils/skipNulls';

/**
 * Роут с дополнительными полями
 */
export type RouterPatched = SingletonRouter & {
	/** название роута */
	routeName: string | null;
	/** метод для получения ссылки на текущий роут с другими параметрами */
	toHref(params: any): string;
	url: Url;
};

type MatchResult = {
	/* параметры для вставки в паттерн */
	params: Record<string, string>;
	/* ссылка */
	parsedUrl: Url;
	/* полные параметры */
	query: Record<string, string>;
	route?: {
		/* название роута: flight-index */
		name: string;
		/* страница: /flight-index */
		page: string;
		/* паттерн */
		pattern: string;
		/* путь по параметрам */
		toPath(params: any): string;
		/* ссылка по параметрам */
		getAs(params: any): string;
	};
};

export type WithRouterPatchedProps = {
	router: RouterPatched;
};

const toHrefByMatch =
	(match: MatchResult) =>
	(params: any): string => {
		if (!match || !match.route) return '';
		const url = match.route.getAs(skipNulls(params));
		if (!url.endsWith('/')) {
			return url + '/';
		}
		return url;
	};

function withRouterPatched(Component: any): any {
	class Wrapper extends React.Component<any> {
		static displayName = `withRouterPatched(${Component.displayName || Component.name})`;

		static contextTypes = {
			router: object,
		};

		render() {
			const router: SingletonRouter = this.props.router;
			const asPath = (router as any)?.state?.asPath || router.asPath;
			const match: MatchResult = router && asPath && routes.match(asPath);
			const routerPatched: RouterPatched = {
				...(router || {}),
				routeName: (match && match.route && match.route.name) || null,
				toHref: toHrefByMatch(match),
				url: match.parsedUrl,
				query: (router as any)?.state?.query || router.query || {},
				asPath,
			};

			return <Component {...this.props} router={routerPatched} />;
		}
	}

	return hoistStatics(Wrapper, Component, {WrappedComponent: true});
}

export default withRouterPatched;
