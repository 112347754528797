import App from 'next/app';
import React from 'react';
import compose from 'lodash.flowright';
import {ApolloProvider} from '@apollo/client';
import {AppInjectRouterContext} from '../components/useRouter';
import withApolloClient from '../lib/withApolloClient';
import {Router} from 'next/router';
import '../styles/variables.css';
// @ts-ignore
import {withServerContext} from 'next-server-context';
import {ToastProvider} from 'react-toast-notifications';
import CustomToastContainer from '../components/CustomToast/CustomToastContainer';
import CustomToast from '../components/CustomToast/CustomToast';
import {CookiesProvider} from 'react-cookie';
import {UserProvider} from '../components/useUser';

type State = {
	runtimeError?: Error;
};

class MyApp extends App<any> {
	state: State = {};

	static async getInitialProps(appContext: any) {
		const appProps = await App.getInitialProps(appContext);

		return {
			...appProps,
			cookies: appContext.ctx.req?.universalCookies,
		};
	}

	componentDidMount(): void {
		// @ts-ignore
		const previousCallback = Router.onRouteChangeComplete;
		// @ts-ignore
		Router.onRouteChangeComplete = () => {
			// call previously defined callback if is a function
			if (typeof previousCallback === 'function') {
				previousCallback();
			}
		};
	}

	componentDidCatch(error: any) {
		if (this.state.runtimeError) {
			return;
		}
		this.setState({
			runtimeError: error,
		});
	}

	render() {
		const {runtimeError} = this.state;
		if (runtimeError) {
			return (
				<>
					<b>error</b>
					{JSON.stringify(runtimeError)}
				</>
			);
		}

		const {Component, pageProps, apolloClient, cookies} = this.props;
		return (
			<CookiesProvider cookies={process.browser ? undefined : cookies}>
				<ApolloProvider client={apolloClient}>
					<AppInjectRouterContext router={this.props.router}>
						<UserProvider>
							<ToastProvider
								placement={'top-center'}
								autoDismiss
								components={{ToastContainer: CustomToastContainer, Toast: CustomToast}}
							>
								<Component {...pageProps} />
							</ToastProvider>
						</UserProvider>
					</AppInjectRouterContext>
				</ApolloProvider>
			</CookiesProvider>
		);
	}
}

export default compose(withApolloClient, withServerContext)(MyApp);
