import React, {useCallback, useContext, useEffect, useMemo, useReducer} from 'react';
import {useCurrentUserQuery, useLogoutMutation, User} from '../generated/types';
import {ActionsUnion, createAction} from './utils/redux';
import {Router} from '../server/routes';

export type State = {
	user?: User;
	logout: boolean;
};

export const initialState: State = {user: undefined, logout: false};

export enum ActionType {
	SET_USER = 'SET_USER',
}

export const actions = {
	setUser: (user?: User) => createAction(ActionType.SET_USER, user),
};

export function reducer(state: State, action: ActionsUnion<typeof actions>): State {
	switch (action.type) {
		case ActionType.SET_USER:
			return {
				...state,
				user: action.payload ? {...action.payload} : undefined,
				logout: !action.payload,
			};
		default:
			return state;
	}
}

export type UserContextType = {
	loading: boolean;
	logoutLoading: boolean;
	logout: () => void;
} & typeof actions &
	User;
export const UserContext = React.createContext<UserContextType>(null as any);

export function UserProvider({children}: any) {
	const redux = useReducer(reducer, initialState);
	const [state, dispatch] = redux;
	const {data, loading} = useCurrentUserQuery({
		ssr: true,
		skip: !!state.user?.email || state.logout,
	});

	const [logoutMutation, {loading: logoutLoading}] = useLogoutMutation();

	useEffect(() => {
		if (!loading && !state.logout && !state.user && data?.currentUser) {
			dispatch(actions.setUser(data.currentUser as User));
		}
	}, [loading, actions.setUser, state.user, state.logout, data]);

	const actionBounds = useMemo(() => {
		const actionBounds = {};
		Object.keys(actions).forEach((actionKey) => {
			// @ts-ignore
			const action = actions[actionKey];
			// @ts-ignore
			actionBounds[actionKey] = (payload) => dispatch(action(payload));
		});
		return actionBounds;
	}, [dispatch]);

	const user = state.user || (!state.logout && data?.currentUser);

	const logout = useCallback(() => {
		logoutMutation()
			.then(() => {
				dispatch(actions.setUser(undefined));
			})
			.catch((e) => console.error(e));
	}, [logoutMutation]);

	const value = {
		...(user || {}),
		...actionBounds,
		logout,
		logoutLoading,
		loading,
	};

	return <UserContext.Provider value={value as any}>{children}</UserContext.Provider>;
}

export function useUser() {
	return useContext(UserContext);
}
